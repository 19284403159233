import styled from 'styled-components';
import { colors } from '../../utils/globals';

export const StyledHome = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #373b44, #4286f4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 10px;
  h1 {
    color: ${colors.white};
    text-align: center;
  }
`;

export default StyledHome;

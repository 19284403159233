import { css } from 'styled-components';

const br = {
  xxs: '350px',
  xs: '640px',
  s: '990px',
  m: '1500px',
  l: '1900px',
};

const or = {
  portrait: 'portrait',
  landscape: 'landscape',
};

export const breakpoint = Object.keys(br).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
          @media screen and (max-width: ${br[label]}) {
            ${css(...args)};
          }
        `;
  return accumulator;
},
{});

export const orientation = Object.keys(or).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
      @media screen and (orientation: ${or[label]}) {
        ${css(...args)};
      }
    `;
  return accumulator;
}, {});

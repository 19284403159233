import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate, Outlet } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';
import axios from '../../utils/axios';
import { StyledHome } from './style';
import QrCodeReader from '../../component/QrCodeReader/QrCodeReader';

const Home = () => (
  <StyledHome>
    <QrCodeReader />
    <Outlet />
  </StyledHome>
);

export default Home;

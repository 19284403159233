import React from 'react';
import {
  createBrowserRouter, RouterProvider, useNavigate,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import Home from './pages/Home';
import NotFound from './pages/NotFound';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    // errorElement: <NotFound />,
  },
]);

const Router = () => (
  <RouterProvider router={router} />
);

export default Router;

import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'react-qr-scanner';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import StyledQrReader from './styled';
import { breakpoint } from '../../utils/mixins';
import { ReactComponent as Check } from '../../svgs/check.svg';

const QRCodeReader = () => {
  const [result, setResult] = useState(null);
  const [user, setUser] = useState();
  const handleScan = (data = {}) => {
    if (_.get(data, 'text', false) && data?.text?.includes(process.env.REACT_APP_BACK_URL)) {
      setResult(data?.text);
    }
  };
  const handleError = (error = {}) => {
    console.error(error);
  };
  const reset = () => {
    setResult(null);
    setUser(null);
  };

  useEffect(() => {
    if (result) {
      const getUser = async () => {
        try {
          const res = await axios.get(result);
          setUser(res?.data);
          await axios.post(`${process.env.REACT_APP_BACK_URL}/apps/signup`, {
            app: process.env.REACT_APP_ID,
            user: res?.data?._id,
          });
          toast.success('QrCode valide !');
        } catch (e) {
          toast.error('QrCode invalide !');
          reset();
        }
      };
      getUser();
    }
  }, [result]);
  return (
    <StyledQrReader>
      <h1>{!user ? 'Veuillez scanner votre QrCode' : 'TakeQr'}</h1>
      {
        !result && !user && (
          <QrScanner
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: breakpoint.xxs ? '100%' : '50%' }}
          />
        )
      }

      {user && (
        <div className="result-wrapper">
          <Check />
          <span>{`Nom: ${user?.lastname}`}</span>
          <span>{`Prénom: ${user?.firstname}`}</span>
          <span>{`Email: ${user?.email}`}</span>
        </div>
      )}

      {
        user && (
          <button type="button" onClick={() => reset()}>fermer</button>
        )
      }

    </StyledQrReader>
  );
};

export default QRCodeReader;

import React from 'react';
import './App.scss';
import { ToastContainer, toast } from 'react-toastify';
import GlobalStyle from './utils/globalStyle';
import Router from './Router';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <ToastContainer
        autoClose={3000}
        position="top-right"
        hideProgressBar={false}
      />
      <Router />
    </div>
  );
}

export default App;
